.overlayCustom {
  background: rgba(255,255,255,.9);
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.disablePointerEvents {
  pointer-events: none;
}

.modalCustom {
  box-shadow: none;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 20px;
}

.menuLangs {
  padding-bottom: 22px;
  margin: 44px 0 0 0;
  border-bottom: 1px solid #ddd;
}

.menuLangs .selected {
  color: #afafaf;
  cursor: default;
}

.menuLangs h3 { margin: 0; cursor: pointer; }

.menuLangs span { 
  font-size: 1.3rem;
  display: inline-block;
  margin: 0 10px; 
}

.listMenu .elencoCantine {
  margin: 10px 0 5px 0;
  color: #868686;
}

.listMenu {
  list-style: none;
  margin: 20px 0;
  padding: 0;
  text-transform: uppercase;
}

.listMenu li { 
  cursor:  pointer; 
  margin: 5px 0; 
  float: left;
  clear: both;
}

.listMenu .cantinaLi { margin-left: 12px; font-size: 1.3rem; }

.logoutMsg { text-transform: none; cursor: default !important; margin: 0 !important; background-color: #eee; padding: 4px; border-color: inherit; border-radius: 5px; margin-top: 4px !important;
}
.logout { text-transform: none; text-decoration: underline; margin: 0 !important; }
.versionepwa { text-transform: none; font-style: italic; }

.listMenu .blogIcon { color: #17a2b8; margin-top: 15px; }
.giftIcon { color: orange; cursor: default; }