svg { width: 90px; height: 90px; overflow: visible !important; cursor: pointer; }
.logoContainer { }
.overlayCustom {
  background: rgba(255,255,255,.9);
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.disablePointerEvents {
  pointer-events: none;
}

.modalCustom {
  box-shadow: none;
  width: 100%;
  height: 100%;
  max-width: 100%;
  padding: 20px;
}

.menuLangs {
  padding-bottom: 22px;
  margin: 44px 0 0 0;
  border-bottom: 1px solid #ddd;
}

.menuLangs .selected {
  color: #afafaf;
  cursor: default;
}

.menuLangs h3 { margin: 0; cursor: pointer; }

.menuLangs span { 
  font-size: 1.3rem;
  display: inline-block;
  margin: 0 10px; 
}

.listMenu .elencoCantine {
  margin: 10px 0 5px 0;
  color: #868686;
}

.listMenu {
  list-style: none;
  margin: 20px 0;
  padding: 0;
  text-transform: uppercase;
}

.listMenu li { 
  cursor:  pointer; 
  margin: 5px 0; 
  float: left;
  clear: both;
}

.listMenu .cantinaLi { margin-left: 12px; font-size: 1.3rem; }

.logoutMsg { text-transform: none; cursor: default !important; margin: 0 !important; background-color: #eee; padding: 4px; border-color: inherit; border-radius: 5px; margin-top: 4px !important;
}
.logout { text-transform: none; text-decoration: underline; margin: 0 !important; }
.versionepwa { text-transform: none; font-style: italic; }

.listMenu .blogIcon { color: #17a2b8; margin-top: 15px; }
.giftIcon { color: orange; cursor: default; }
.menuToggle
{
    display: block;
    position: absolute;
    z-index: 10001;
    right: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
}

.menuToggle input
{
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
}

.menuToggle span
{
    display: block;
    width: 39px;
    height: 3px;
    margin: 0 auto 7px;
    position: relative;
    background: #000000;
    border-radius: 3px;
    z-index: 1;
    -webkit-transform-origin: 4px 0px;
            transform-origin: 4px 0px;
    -webkit-transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    transition: background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease, -webkit-transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
}

.menuToggle span:first-child
{
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
}

.menuToggle span:nth-last-child(1)
{
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
}

.menuToggle input:checked ~ span
{
    opacity: 1;
    -webkit-transform: rotate(45deg) translate(-2px, -1px);
            transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
}

.menuToggle input:checked ~ span:nth-last-child(2)
{
    opacity: 0;
    -webkit-transform: rotate(0deg) scale(0.2, 0.2);
            transform: rotate(0deg) scale(0.2, 0.2);
}

.menuToggle input:checked ~ span:nth-last-child(1)
{
    -webkit-transform: rotate(-45deg) translate(0, 2px);
            transform: rotate(-45deg) translate(0, 2px);
}

.menuToggle.reset input:checked ~ span {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
}
.header {
    height: 135px;
    position: relative;
}
.footer { height: 100px; background-color: #ececec; margin: 20px 0 0 0; }
.loginAuth .logoContainer svg { width: 120px !important; height: 120px !important; }
.loginAuth { margin-top: 30px; margin-bottom: 30px; }
.loginAuth .logoContainer { text-align: center; }
.titleLogin { display: block; margin-bottom: 20px; }
.boxLogin { margin: 50px 0; }
.eyePasswordShow { position: absolute; z-index: 100; right: 11px; top: 7px; display: block; }
.eyePasswordHide { position: absolute; z-index: 100; right: 10px; top: 7px; display: block; }
.eyePasswordHide { display: none; }
.eyePasswordShow.selected { display: none; }
.eyePasswordHide.selected { display: block; }
.loginAuth .input-group input { border-radius: 0.25rem !important; }
.sweet-loading { position: absolute; z-index: 1; }
.slideshowCantina h1 {
	text-transform: uppercase;
}
.containerSlideshowCantine {
	position: relative;
}
.logoCantinaInterno {
	position: absolute;
	left: 10px;
	z-index: 4;
	background-color: rgba(250, 250, 250, 1);
	max-width: 140px;
}
.bottigliaCantina {
	position: absolute;
	left: 50%;
	z-index: 4;
	-webkit-transform: translateX(-50%) translateY(-50%);
	        transform: translateX(-50%) translateY(-50%);
	top: 50%;
}
.bottigliaVino {
	pointer-events: none;
	position: absolute;
	left: 29%;
	z-index: 4;
	-webkit-transform: translateX(-29%) translateY(-50%);
	        transform: translateX(-29%) translateY(-50%);
	top: 50%;
}
.bottigliaGift {
	max-width: 100%;
}
.scrittaSlideVinoCont {
	display: -webkit-flex;
	display: flex;
	-webkit-justify-content: center;
	        justify-content: center;
}
.scrittaSlideVino {
	display: inline-block;
	color: #a4a4a4;
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
	height: 31px;
	top: 0;
	margin-left: 80px;
	padding: 0 10px;
	font-style: italic;
	background: -webkit-linear-gradient(
		bottom,
		transparent 40%,
		#a4a4a4 40%,
		#a4a4a4 45%,
		transparent 45%
	);
	background: linear-gradient(
		to top,
		transparent 40%,
		#a4a4a4 40%,
		#a4a4a4 45%,
		transparent 45%
	);
	box-sizing: border-box;
}
.scrittaSlideVino span {
	background-color: #ececec;
	padding: 0 10px;
	white-space: nowrap;
}
.downloadPdf {
	position: absolute;
	right: 0;
	top: -30px;
	cursor: pointer;
	z-index: 999 !important;
}
.downloadPdf span {
	color: red;
	font-style: italic;
	margin-right: 10px;
}
.keyTabella {
	color: #444445;
	border-top: 2px solid #ececec;
	text-align: right;
	font-style: italic;
	padding: 2px 10px;
}
.valueTabella {
	color: #1d1d1b;
	border-top: 2px solid #dfdfdf;
	padding: 2px 10px;
}
.formatoBottiglia img {
	margin-bottom: 5px;
}
.titoloPrezzo {
	margin: 20px 0 5px 0;
}
.rowPrezzi {
	padding: 3px 0;
	color: #000;
}
.rowPrezzi.selected {
	background-color: #fcff84 !important;
}
.rowPrezzi:nth-child(even) {
	background-color: #d1d1d1;
}
.rowPrezzi:nth-child(odd) {
	background-color: #f6f5f5;
}

.rowPrezzi.listino:nth-child(2n + 1) {
	background-color: #f6f5f5;
}
/* .rowPrezzi.listino:nth-child(odd) { background-color: #d1d1d1; } */

.giftThumb {
	max-height: 130px;
}

.vinoZoom {
	max-width: 100%;
}
.photogallery_container div {
	z-index: 10;
	cursor: pointer;
}
.photogallery_container div img {
	padding: 5px;
}

/* POPUP REACT */
.popupbox {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center;
	opacity: 0;
	pointer-events: none;
	z-index: 99999;
}

.popupbox[hidden] {
	display: none;
}

.popupbox-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1002;
	background: none repeat scroll 0% 0% #000;
}

.popupbox-content {
	overflow: hidden;
}

.popupbox-wrapper {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	z-index: 1003;
	position: relative;
}

.popupbox-titleBar {
	overflow: hidden;
	display: block;
	position: relative;
}

.popupbox-btn--close {
	z-index: 1004;
}

.popupbox[data-title='bottom'] .popupbox-content {
	-webkit-order: 1;
	        order: 1;
}
.popupbox[data-title='bottom'] .popupbox-titleBar {
	-webkit-order: 2;
	        order: 2;
}

/* ----- default theme ----- */

.popupbox-wrapper {
	border-radius: 3px;
	overflow: hidden;
	max-width: 80%;
	min-width: 300px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
	background-color: white;
}

.popupbox-content {
	padding: 20px 24px 30px;
}

.popupbox-titleBar {
	font-weight: bold;
	font-size: 18px;
	text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
	padding: 11px 37px 11px 24px;
	border-bottom: 1px #ccc solid;
}

.popupbox-btn--close {
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	position: absolute;
	right: 11px;
	top: 11px;
	color: #c1c1c1;
	background: none;
	border: none;
	outline: none;
}

.popupbox-btn--close:hover {
	color: #000;
}

.popupbox.is-active {
	opacity: 1;
	pointer-events: auto;
}

.popupbox[data-title='bottom'] .popupbox-content {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.popupbox[data-title='bottom'] .popupbox-titleBar {
	box-shadow: none;
	border-top: 1px #ccc solid;
}

@media (max-width: 992px) {
	.scrittaSlideVinoCont {
		margin: 5px 0 20px 5px;
		background: -webkit-linear-gradient(
			top,
			transparent 40%,
			#a4a4a4 40%,
			#a4a4a4 45%,
			transparent 45%
		);
		background: linear-gradient(
			to bottom,
			transparent 40%,
			#a4a4a4 40%,
			#a4a4a4 45%,
			transparent 45%
		);
	}
	.downloadPdf {
		top: 0px;
	}
	.slideshowVino .pl-5 {
		padding: 1rem 2rem !important;
	}
	.bottigliaVino {
		-webkit-transform: none;
		        transform: none;
		left: auto;
		bottom: 0;
		top: 7%;
		right: 10%;
		max-height: 565px;
	}
	.scrittaSlideVino {
		-webkit-transform: none;
		        transform: none;
		padding: 0;
		margin: 0;
		background: none;
	}
	.slideshowVino .slick-dots {
		bottom: auto;
		top: 10px;
		right: 5%;
		left: auto;
		-webkit-transform: none;
		        transform: none;
	}
	.slideshowVino .slick-dots li {
		margin: 0 9px;
	}
	.slideshowVino h3 {
		font-size: 1.375rem;
		line-height: 1.5;
	}
	.slideshowVino p {
		font-size: 1rem;
		line-height: 1.8;
	}
}

@media (max-width: 768px) {
	.bottigliaVino {
		top: 100px;
	}
}

@media (max-width: 576px) {
	.bottigliaVino {
		top: 100px;
		right: 5%;
		max-height: 450px;
	}
	.bottigliaGift {
		max-height: 300px;
	}
	.slideshowVino .pl-5 {
		padding: 2rem !important;
	}
	.slideshowVino .slick-dots {
		top: 50px;
	}
	.downloadPdf {
		right: 20px;
		top: -10px;
	}
	.scrittaSlideVinoCont {
		margin: 30px 0 20px 0;
	}
	.keyTabella {
		padding: 2px 8px 2px 0 !important;
	}
	.valueTabella {
		padding: 2px 0 0 8px !important;
	}
	.keyTabella,
	.valueTabella {
		font-size: 0.875rem !important;
		line-height: 1.5 !important;
	}
}

@media (max-width: 500px) {
	.pSlideshow {
		min-height: 65px;
	}
}

@media (max-width: 480px) {
	.bottigliaVino {
		top: 100px;
		max-height: 380px;
	}
	.formatoBottiglia span {
		font-size: 0.875rem;
		line-height: 1.5;
	}
	.titoloPrezzo {
		font-size: 0.6875rem;
		line-height: 1.5;
	}
}

@media (max-width: 400px) {
	.bottigliaVino {
		top: 100px;
		max-height: 320px;
	}
	.slideshowVino .pl-5 {
		padding: 2rem !important;
	}
	.slideshowVino h3 {
		font-size: 1rem;
		line-height: 1.5;
	}
	.slideshowVino p {
		font-size: 0.875rem;
		line-height: 1.5;
	}
	.formatoBottiglia span {
		font-size: 0.6875rem;
		line-height: 1.5;
	}
	.titoloPrezzo {
		font-size: 0.625rem;
		line-height: 1.5;
	}
	.rowPrezzi {
		font-size: 0.6875rem;
		line-height: 1.5;
	}
}

@media (max-width: 350px) {
	.bottigliaVino {
		top: 80px;
		max-height: 300px;
	}
	.slideshowVino .pl-5 {
		padding: 2rem !important;
	}
	.slideshowVino h3 {
		font-size: 1rem;
		line-height: 1.5;
	}
	.slideshowVino p {
		font-size: 0.875rem;
		line-height: 1.5;
	}
	.formatoBottiglia span {
		font-size: 0.625rem;
		line-height: 1.5;
	}
}

.cantineContainer {
	margin: 100px 0 0 0;
}

.spinnerCentered {
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
}

.gruppoCantine {
}
.gruppoCantine > div {
	opacity: 0;
	-webkit-transition: all 700ms ease-in-out;
	transition: all 700ms ease-in-out;
}
.appear {
	opacity: 1;
}
.boxCantina {
	position: relative;
	overflow: hidden;
	cursor: pointer;
}
.immaginePrincipale,
.immagineAlternativa {
	width: 100%;
}
.immaginePrincipale.colore,
.immagineAlternativa.colore {
	opacity: 0;
	position: absolute;
}
.boxCantina .immagineAlternativa {
	display: none;
}
.boxCantina span {
	width: 100%;
}
.boxCantina h1 {
	color: white;
	position: absolute;
	text-transform: uppercase;
	opacity: 0;
	z-index: 4;
}
.logoCantina {
	position: absolute;
	z-index: 4;
	pointer-events: none;
	background-color: rgba(250, 250, 250, 1);
	max-width: 120px !important;
}
.titoloCantina {
	pointer-events: none;
}
.gridCantina {
	overflow: hidden;
}
.gridCantina.uno .logoCantina {
	right: 0;
}
.gridCantina.due .logoCantina {
	right: 0;
	bottom: 0;
}
.gridCantina.tre .logoCantina {
	right: 0;
	top: 0;
}
.gridCantina.quattro .logoCantina {
	right: 0;
	bottom: 0;
}
.gridCantina.cinque .logoCantina {
	left: 0;
	bottom: 0;
}
.gridCantina.sei .logoCantina {
	right: 0;
	bottom: 0;
}
.gridCantina.uno .boxCantina h1 {
	bottom: 30px;
	left: 20px;
}
.gridCantina.due .boxCantina h1 {
	bottom: 240px;
	text-align: center;
}
.gridCantina.tre .boxCantina h1 {
	top: 250px;
	text-align: center;
}
.gridCantina.quattro .boxCantina h1 {
	bottom: 30px;
	left: 20px;
}
.gridCantina.cinque .boxCantina h1 {
	bottom: 30px;
	right: 20px;
}
.gridCantina.sei .boxCantina h1 {
	bottom: 30px;
	left: 20px;
}
.gridCantina.due {
	margin-top: 40px;
}
.gridCantina.quattro {
	margin-top: 40px;
}
.gridCantina.cinque {
	margin-top: -40px;
}
.gridCantina img {
	max-width: 100%;
	height: auto;
}

@media (max-width: 991px) {
	.cantineContainer {
		margin-top: 20px;
	}
	#gridCantine {
		grid-template-columns: repeat(18, 1fr);
		grid-auto-rows: 10px;
	}
	.gridCantina {
		text-align: center;
	}
	.gridCantina img {
		max-height: 100%;
		max-width: 100%;
		height: auto;
	}
	.gridCantina.uno {
		grid-column-end: span 8;
		grid-row-end: span 14;
	}
	.gridCantina.due {
		grid-column-end: span 5;
		grid-row-end: span 33;
		margin: 0;
	}
	.gridCantina.tre {
		grid-column-end: span 5;
		grid-row-end: span 33;
	}
	.gridCantina.quattro {
		grid-column-end: span 8;
		grid-row-end: span 14;
		margin: 0;
	}
	.gridCantina.cinque {
		grid-column-end: span 8;
		grid-row-end: span 14;
		margin: 0;
	}
	.gridCantina.sei {
		grid-column-end: span 8;
		grid-row-end: span 14;
		margin: 0 0 0 3px;
	}
}

@media (max-width: 767px) {
	.cantineContainer {
		margin-top: 20px;
	}
	#gridCantine {
		grid-template-columns: repeat(18, 1fr);
		grid-auto-rows: 9px;
	}
	.gridCantina {
		text-align: center;
	}
	.gridCantina img {
		max-height: 100%;
	}
	.gridCantina.uno {
		grid-column-end: span 9;
		grid-row-end: span 12;
	}
	.gridCantina.due {
		grid-column-end: span 9;
		grid-row-end: span 12;
		margin: 0;
	}
	.gridCantina.tre {
		grid-column-end: span 9;
		grid-row-end: span 12;
	}
	.gridCantina.quattro {
		grid-column-end: span 9;
		grid-row-end: span 12;
		margin: 0;
	}
	.gridCantina.cinque {
		grid-column-end: span 9;
		grid-row-end: span 12;
		margin: 0;
	}
	.gridCantina.sei {
		grid-column-end: span 9;
		grid-row-end: span 12;
		margin: 0;
	}
	.gridCantina .logoCantina {
		max-height: 120px;
	}
	.gridCantina.uno .logoCantina {
		right: 15px;
		margin-top: -190px;
	}
	.gridCantina.due .logoCantina {
		right: 15px;
		top: 0;
		margin-bottom: 0;
		margin-top: -190px;
	}
	.gridCantina.tre .logoCantina {
		left: 15px;
		top: 0;
		margin-top: -190px;
	}
	.gridCantina.quattro .logoCantina {
		left: 15px;
		top: 0;
		margin-bottom: 0;
		margin-top: -190px;
	}
	.gridCantina.cinque .logoCantina {
		right: 15px;
		top: 0;
		margin-bottom: 0;
		margin-top: -190px;
	}
	.gridCantina.sei .logoCantina {
		right: 15px;
		top: 0;
		margin-bottom: 0;
		margin-top: -190px;
	}
	.gridCantina.due .boxCantina h1 {
		bottom: 30px;
		left: 12px;
		-webkit-transform: none;
		        transform: none;
	}
	.gridCantina.tre .boxCantina h1 {
		bottom: 30px;
		left: 12px;
		top: auto;
		-webkit-transform: none;
		        transform: none;
	}
	.gridCantina.due .boxCantina .immagineAlternativa,
	.gridCantina.tre .boxCantina .immagineAlternativa {
		display: block;
	}
	.gridCantina.due .boxCantina .immaginePrincipale,
	.gridCantina.tre .boxCantina .immaginePrincipale {
		display: none;
	}
}

@media (max-width: 576px) {
	#gridCantine {
		grid-auto-rows: minmax(20px, auto);
	}
	.gridCantina {
		grid-row-end: auto !important;
	}
	.gridCantina .logoCantina {
		max-height: 120px;
	}
}

@media (max-width: 500px) {
	#gridCantine {
		width: 100%;
	}
	.gridCantina {
		grid-column-end: span 18 !important;
		grid-row-end: auto !important;
	}
	.gridCantina .immaginePrincipale,
	.gridCantina .immagineAlternativa {
		width: 100%;
	}
	.gridCantina.cinque .boxCantina h1 {
		left: 20px;
		right: auto;
	}
	.gridCantina .logoCantina {
		max-height: none;
		right: 15px !important;
		left: auto !important; /*  bottom: auto !important; top: -120px;   */
	}
}

.bg-grigio { background-color: #ececec; }
.slideshow {  overflow: hidden; }
.slick-slider { width: 100%; z-index: 2 }
.slick-slider img { line-height: 0; display: inline; z-index: -1; }
.slick-dots { width: auto; bottom: 5%; -webkit-transform: translateY(-60%); transform: translateY(-60%); left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); }
.slick-dots li { width: 26px; height: 6px; }
.slick-dots li button { background: #b5b5b5; width: 26px; height: 6px; padding: 0; }
.slick-dots li button::before { display: none; }
.slick-dots li.slick-active button { background: #1d5c39; }
.spinnerSlideshow { right: 50%; -webkit-transform: translateX(50%); transform: translateX(50%); }
.mappaItalia { position: absolute; left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); bottom: -150px; opacity: .1; z-index: 3; }

@media (min-width: 768px) {
  .slick-dots { width: 26px; bottom: auto; top: 50%; -webkit-transform: translateY(-50%); transform: translateY(-50%); left: 24px; }
  .spinnerSlideshow { right: 25%; -webkit-transform: translateX(50%); transform: translateX(50%); }
  .slick-dots { bottom: 60%; }
}

@media (min-width: 300px) {
  .mappaItalia { bottom: 0; z-index: 1; }
}
.filtri { background-color: #ececec; margin: 20px 0 20px 0; }
.filtri .form-group { margin-bottom: 0; position: relative; }
.filtri .form-group svg { position: absolute; left: 10px; margin: 0; line-height: 1 !important; color: #f4313f; }
.filtri .form-control { border: 0; border-radius: 0; }
.filtri .form-control:focus { border-color: #b5b5b5 !important; box-shadow: 0 0 0 0.2rem rgba(181, 181, 181, 0.25) }
.filtri .form-group.cercaProdotto { margin-bottom: 10px; }
.filtri .form-group.cercaProdotto input { padding-left: 40px !important; margin-bottom: 0; }
.inviaFiltro { margin: 0 8px; }
.form-control { cursor: pointer; }
.form-control:disabled, .form-control[readonly] {
  background-color: #dedede !important; 
  cursor: default;
}

@media (max-width: 1200px) {
  .inviaFiltro { margin: 0 2px; }
}

@media (max-width: 992px) {
  .form-control { margin-bottom: 10px; }
}
.boxVino > div { opacity: 0; -webkit-transition: all 700ms ease-in-out; transition: all 700ms ease-in-out; margin-bottom: 50px; cursor: pointer; }
.boxVino h3 { display: block; text-align: center; }
.boxVino h4 { display: block; text-align: center; margin-bottom: 5px; }
.boxVino .immagineVinoElenco { max-height: 350px; }
.boxVino .selected img { padding: 5px; border-top: 2px solid #d1d1d1; }
.boxVino .selected::before { content: ''; position: absolute; border-left: 2px solid #d1d1d1; z-index: 999; width: 5px; height: 30px; left: 56px; top: 0; }
.boxVino .selected::after { content: ''; position: absolute; border-right: 2px solid #d1d1d1; z-index: 999; width: 5px; height: 30px; right: 56px; top: 0; }
.lineaRossa { width: 100%; height: 45px; margin: 15px 0; background: -webkit-linear-gradient(left, transparent 50%, #d1d1d1 50%, #d1d1d1 51%, transparent 51% ); background: linear-gradient(to right, transparent 50%, #d1d1d1 50%, #d1d1d1 51%, transparent 51% ); }

.boxVino .Bellavista.selected::before { border-left: 2px solid #f4313f; }
.boxVino .Bellavista.selected::after { border-right: 2px solid #f4313f; }
.boxVino .Bellavista.selected img { border-top: 2px solid #f4313f; }
.boxVino .Acquagiusta.selected::before { border-left: 2px solid #cdb4a7; }
.boxVino .Acquagiusta.selected::after { border-right: 2px solid #cdb4a7; }
.boxVino .Acquagiusta.selected img { border-top: 2px solid #cdb4a7; }
.boxVino .ContadiCastaldi.selected::before { border-left: 2px solid #dddc00; }
.boxVino .ContadiCastaldi.selected::after { border-right: 2px solid #dddc00; }
.boxVino .ContadiCastaldi.selected img { border-top: 2px solid #dddc00; }
.boxVino .SellaMosca.selected::before { border-left: 2px solid #58b1c1; }
.boxVino .SellaMosca.selected::after { border-right: 2px solid #58b1c1; }
.boxVino .SellaMosca.selected img { border-top: 2px solid #58b1c1; }
.boxVino .Petra.selected::before { border-left: 2px solid #7a5b3a; }
.boxVino .Petra.selected::after { border-right: 2px solid #7a5b3a; }
.boxVino .Petra.selected img { border-top: 2px solid #7a5b3a; }
.boxVino .Teruzzi.selected::before { border-left: 2px solid #84754f; }
.boxVino .Teruzzi.selected::after { border-right: 2px solid #84754f; }
.boxVino .Teruzzi.selected img { border-top: 2px solid #84754f; }

@media (max-width: 1200px) {
    .boxVino .selected::before { left: 35px; }
    .boxVino .selected::after { right: 35px; }
}

@media (max-width: 769px) {
    .boxVino .selected::before { left: 45px; }
    .boxVino .selected::after { right: 45px; }
}

@media (max-width: 576px) {
    .boxVino .selected { border-top: 2px solid #d1d1d1; }
    .boxVino .selected img { border: 0 !important; }
    .boxVino .selected::before { left: 0; }
    .boxVino .selected::after { right: 0; }

    .boxVino .Bellavista.selected { border-top: 2px solid #f4313f; }
    .boxVino .Acquagiusta.selected { border-top: 2px solid #cdb4a7; }
    .boxVino .ContadiCastaldi.selected { border-top: 2px solid #dddc00; }
    .boxVino .SellaMosca.selected { border-top: 2px solid #58b1c1; }
    .boxVino .Petra.selected { border-top: 2px solid #7a5b3a; }
    .boxVino .Teruzzi.selected { border-top: 2px solid #84754f; }
}
.spinnerSlideshow.leftOriented { left: 25%; -webkit-transform: translateX(-50%); transform: translateX(-50%); }
.bottigliaCantina { pointer-events: none; }
.appear { opacity: 1; }
.clearfix:before, .clearfix:after { content: "."; display: block; height: 0; overflow: hidden; } 
.clearfix:after { clear: both; } 
.clearfix { zoom: 1; /* IE < 8 */ }

@media (max-width: 992px) {
  .slideshowCantina .pl-5 {
    padding: 1rem 2rem !important;
  }
  .slideshowCantina .slick-dots { bottom: auto; top: 5%; right: 5%; left: auto; }
  .slideshowCantina .slick-dots li { margin: 0 9px;}
  .slideshowCantina h3 { font-size: 1.3750rem; line-height: 1.5; }
  .slideshowCantina p { font-size: 1rem; line-height: 1.8; }
  .immagineSlideshowP { width: 100%; position: relative; max-height: 600px; }
  .immagineSlideshowP img { width: 100%; }
  .spinnerSlideshow.leftOriented { left: 50%; top: 20%; right: auto; -webkit-transform: translateX(0); transform: translateX(0);  }
  .bottigliaCantina { -webkit-transform: none; transform: none; left: auto; bottom: 0; top: 20%; right: 10%; }
}

@media (max-width: 768px) {
  .immagineSlideshowP { max-height: none; }
  .immagineSlideshowP img { -webkit-transform: none; transform: none; top: 0; }
  .bottigliaCantina { top: 15%; }
}

@media (max-width: 576px) {
  .bottigliaCantina { top: 12%; max-height: 450px; }
  .slideshowCantina .pl-5 { padding: 2rem !important; }
  .slideshowCantina .slick-dots { right: 0; }
}

@media (max-width: 480px) {
  .bottigliaCantina { top: 9%; max-height: 380px; }
}

@media (max-width: 400px) {
  .bottigliaCantina { top: 9%; max-height: 320px; }
  .slideshowCantina .pl-5 { padding: 2rem !important; }
  .slideshowCantina h3 { font-size: 1rem; line-height: 1.5; }
  .slideshowCantina p { font-size: 0.875rem; line-height: 1.5; }
}

@media (max-width: 350px) {
  .bottigliaCantina { top: 8%; max-height: 300px; }
  .slideshowCantina .pl-5 { padding: 2rem !important; }
  .slideshowCantina h3 { font-size: 1rem; line-height: 1.5; }
  .slideshowCantina p { font-size: 0.875rem; line-height: 1.5; }
}
.spinnerSlideshow.leftOriented { left: 25%; -webkit-transform: translateX(-50%); transform: translateX(-50%); text-align: center; }
.bottigliaCantina { pointer-events: none; }
.filtrandoPer { margin: 0 0 10px 0; background-color: #ececec; }
.filtrandoPer b { margin-left: 5px; }

@media (max-width: 992px) {
  .slideshowCantina .pl-5 {
    padding: 1rem 2rem !important;
  }
  .slideshowCantina .slick-dots { bottom: auto; top: 5%; right: 5%; left: auto; -webkit-transform: none !important; transform: none !important; }
  .slideshowCantina .slick-dots li { margin: 0 9px;}
  .slideshowCantina h3 { font-size: 1.3750rem; line-height: 1.5; }
  .slideshowCantina p { font-size: 1rem; line-height: 1.8; }
  .immagineSlideshowP { width: 100%; position: relative; max-height: 600px; }
  .immagineSlideshowP img { width: 100%; }
  .spinnerSlideshow.leftOriented { left: 50%; top: 20%; right: auto; -webkit-transform: translateX(0); transform: translateX(0);  }
  .bottigliaCantina { -webkit-transform: none; transform: none; left: auto; bottom: 0; top: 20%; right: 10%; }
}

@media (max-width: 768px) {
  .immagineSlideshowP { max-height: none; }
  .immagineSlideshowP img { -webkit-transform: none; transform: none; top: 0; }
  .bottigliaCantina { top: 15%; }
}

@media (max-width: 576px) {
  .bottigliaCantina { top: 12%; max-height: 450px; }
  .slideshowCantina .pl-5 { padding: 2rem !important; }
}

@media (max-width: 480px) {
  .bottigliaCantina { top: 9%; max-height: 380px; }
}

@media (max-width: 400px) {
  .bottigliaCantina { top: 9%; max-height: 320px; }
  .slideshowCantina .pl-5 { padding: 2rem !important; }
  .slideshowCantina h3 { font-size: 1rem; line-height: 1.5; }
  .slideshowCantina p { font-size: 0.875rem; line-height: 1.5; }
}

@media (max-width: 350px) {
  .bottigliaCantina { top: 8%; max-height: 300px; }
  .slideshowCantina .pl-5 { padding: 2rem !important; }
  .slideshowCantina h3 { font-size: 1rem; line-height: 1.5; }
  .slideshowCantina p { font-size: 0.875rem; line-height: 1.5; }
}
.filtrandoPer { margin: 0 0 10px 0; background-color: #ececec; }
.filtrandoPer b { margin-left: 5px; }
.boxGift > div { opacity: 0; -webkit-transition: all 700ms ease-in-out; transition: all 700ms ease-in-out; margin-bottom: 50px; cursor: pointer; }
.boxGift h3 { display: block; text-align: center; }
.boxGift h4 { display: block; text-align: center; margin-bottom: 5px; }
.boxGift .immagineGiftElenco { max-width: 100%; height: 182px; }
.boxGift .selected img { padding: 10px 20px; border-left: 2px solid #d1d1d1; border-right: 2px solid #d1d1d1; border-top: 2px solid #d1d1d1; }
.lineaRossa { width: 100%; height: 45px; margin: 15px 0; background: -webkit-linear-gradient(left, transparent 50%, #d1d1d1 50%, #d1d1d1 51%, transparent 51% ); background: linear-gradient(to right, transparent 50%, #d1d1d1 50%, #d1d1d1 51%, transparent 51% ); }

.boxGift .Bellavista.selected img { border-left: 2px solid #f4313f; border-right: 2px solid #f4313f; border-top: 2px solid #f4313f; }
.boxGift .Acquagiusta.selected img { border-left: 2px solid #cdb4a7; border-right: 2px solid #cdb4a7; border-top: 2px solid #cdb4a7; }
.boxGift .ContadiCastaldi.selected img { border-left: 2px solid #dddc00; border-right: 2px solid #dddc00; border-top: 2px solid #dddc00; }
.boxGift .SellaMosca.selected img { border-left: 2px solid #58b1c1; border-right: 2px solid #58b1c1; border-top: 2px solid #58b1c1; }
.boxGift .Petra.selected img { border-left: 2px solid #7a5b3a; border-right: 2px solid #7a5b3a; border-top: 2px solid #7a5b3a; }
.boxGift .Teruzzi.selected img { border-left: 2px solid #84754f; border-right: 2px solid #84754f; border-top: 2px solid #84754f; }

@media (max-width: 1200px) {
    .boxGift .selected::before { left: 35px; }
    .boxGift .selected::after { right: 35px; }
}

@media (max-width: 769px) {
    .boxGift .selected::before { left: 45px; }
    .boxGift .selected::after { right: 45px; }
}

@media (max-width: 576px) {

}

.elencoGift { margin-top: 20px; }

.spinnerSlideshow.leftOriented { left: 25%; -webkit-transform: translateX(-50%); transform: translateX(-50%); }
.spinnerSlideshow.centerOriented { left: 50%; -webkit-transform: translateX(-50%); transform: translateX(-50%); }
.spinnerSlideshow.hide { display: none; }
.bottigliaCantina { pointer-events: none; }
.appear { opacity: 1; }

@media (max-width: 992px) {
  .slideshowCantina .pl-5 {
    padding: 1rem 2rem !important;
  }
  .slideshowCantina .slick-dots { bottom: auto; top: 5%; right: 5%; left: auto; }
  .slideshowCantina .slick-dots li { margin: 0 9px;}
  .slideshowCantina h3 { font-size: 1.3750rem; line-height: 1.5; }
  .slideshowCantina p { font-size: 1rem; line-height: 1.8; }
  .immagineSlideshowP { width: 100%; position: relative; max-height: 600px; }
  .immagineSlideshowP img { width: 100%; }
  .spinnerSlideshow.leftOriented { left: 50%; top: 20%; right: auto; -webkit-transform: translateX(0); transform: translateX(0);  }
  .bottigliaCantina { -webkit-transform: none; transform: none; left: auto; bottom: 0; top: 20%; right: 10%; }
}

@media (max-width: 768px) {
  .immagineSlideshowP { max-height: none; }
  .immagineSlideshowP img { -webkit-transform: none; transform: none; top: 0; }
  .bottigliaCantina { top: 15%; }
}

@media (max-width: 576px) {
  .bottigliaCantina { top: 12%; max-height: 450px; }
  .slideshowCantina .pl-5 { padding: 2rem !important; }
  .slideshowCantina .slick-dots { right: 0; }
}

@media (max-width: 480px) {
  .bottigliaCantina { top: 9%; max-height: 380px; }
}

@media (max-width: 400px) {
  .bottigliaCantina { top: 9%; max-height: 320px; }
  .slideshowCantina .pl-5 { padding: 2rem !important; }
  .slideshowCantina h3 { font-size: 1rem; line-height: 1.5; }
  .slideshowCantina p { font-size: 0.875rem; line-height: 1.5; }
}

@media (max-width: 350px) {
  .bottigliaCantina { top: 8%; max-height: 300px; }
  .slideshowCantina .pl-5 { padding: 2rem !important; }
  .slideshowCantina h3 { font-size: 1rem; line-height: 1.5; }
  .slideshowCantina p { font-size: 0.875rem; line-height: 1.5; }
}
.thead .titoloPrezzo { margin-top: 10px; }
.filtrandoPer { margin: 0 0 10px 0; background-color: #ececec; }
.filtrandoPer b { margin-left: 5px; }
#elencoPrezzi .rowPrezzi .nomeVino { padding: 0 15px }
.disponibilita { color: green; }
.showMobile.dispo { display: none; }

@media (max-width: 768px) {
    .rowPrezzi div { padding: 5px 5px !important; font-size: 0.875rem; line-height: 1.5; }
    .vaiProdotto { display: block; clear: both; }
    .showMobile.dispo { background: #9dc88c; color: black; padding: 0 5px 0 0; text-align: right; display: block; }
    #elencoPrezzi .disponibilita { display: none !important; }
    #elencoPrezzi .nomeVino { -webkit-flex: 0 0 33.333333%; flex: 0 0 33.333333%; max-width: 33.333333%; }
    /* #elencoPrezzi .nomeVino { flex: 0 0 33.333333%; max-width: 33.333333%; }
    #elencoPrezzi .formatoDesc { flex: 0 0 25%; max-width: 25%; }
    #elencoPrezzi .annata { flex: 0 0 8.333333%; max-width: 8.333333%; } */
}

@media (max-width: 480px) {
    /* #elencoPrezzi .nomeVino { flex: 0 0 25%; max-width: 25%; } */
/*     #elencoPrezzi .prezzi { flex: 0 0 16.666667%; max-width: 16.666667%; } */
    .rowPrezzi div { padding: 3px 3px !important; font-size: 0.7rem; line-height: 1.5; }
    .showMobile.dispo {  font-size: 0.875rem; }
}
.blogIcon { color: #17a2b8; cursor: default; }
.blogIcon svg { cursor: default; }
.immagineCont { max-width: 230px; height: 145px; overflow: hidden; float: left; }
.btn-link { color: #17a2b8 !important; }
.tagsCont { float: left; margin: 10px 0 0 0; }
.showFiltri { display: none !important; background-color: #17a2b8 !important; }
.infinite-scroll-component { overflow-x: hidden !important; }

@media (max-width: 900px) {
    .immagineCont  { clear: both; width: 100%; border: 0 !important; padding: 0 !important; max-width: none; }
    .immagineThumb { width: 100%; }
    .descrizione { float: left; }
} 

@media (max-width: 768px) {
    .leftCol { display: none; opacity: 0; -webkit-transition: all 200ms linear;
        transition: all 200ms linear; }
    .leftCol.toggled { display: block; opacity: 1; }
    .showFiltri { display: inline-block !important; }
    .hideM { height: 10px !important; }
}
.tags { padding-left: 0 !important; }
.tags .badge { margin: 2px; cursor: pointer; }
.categorieBlog { padding-left: 0 !important; }
.categorieBlog h4 { margin-bottom: 15px; }
.categorieBlog button { border: 0; border-radius: 0; background-color: #e3e3e3; color: black; }
.categorieBlog button:hover, .categorieBlog button:active, .categorieBlog .selected { color: white !important; background-color: inherit; }
.categorieBlog div { margin: 7px 0; }
.tags h4, .categorieBlog h4 { color: #245d38; }

@media (max-width: 400px) {
    .spacer30 { height: 15px !important; }
}


.immagineBig, .immagineThumb { cursor: pointer; float: left; }
.immagineContBig { float: left; }
.blogZoom { max-width: 100%; }
.tagsCont { padding: 0 !important; margin: 0 0 10px 0; float: none; }

@media (max-width: 576px) {
    .immagineBig { width: 100% !important; }
    .immagineContBig { float: none !important; }
}


.root { color: #444445; font-family: 'MontserratRegular', Georgia, serif; }
body { overflow-x: hidden; }
html { font-size: 12px; overflow: visible !important; position: relative !important; top: auto !important; width: 100% !important; }
p { margin-bottom: 0 !important; }

@media (min-width: 300px) {
  html { font-size: 13px; }
}

@media (min-width: 350px) {
  html { font-size: 14px; }
}

@media (min-width: 576px) {
  html { font-size: 14px; }
}

@media (min-width: 768px) {
  html { font-size: 14px; }
}

@media (min-width: 992px) {
  html { font-size: 15px; }
}

@media (min-width: 1200px) {
  html { font-size: 16px; }
}

/* UTILS */
.overflowh { overflow: hidden; }

/* FONTS */
.fsemib { font-family: 'MontserratSemiBold', Georgia, serif; }

/* SPACER */
.spacer15 { height: 15px; }
.spacer30 { height: 30px; }

/* FONT-SIZE */
.fsize12 { font-size: 0.750rem; line-height: 1.5; }
.fsize14 { font-size: 0.875rem; line-height: 1.5; }
.fsize15 { font-size: 0.937rem; line-height: 1.5; }
.fsize18 { font-size: 0.875rem; line-height: 1.5; }
.fsize19 { font-size: 1rem; line-height: 1.5; }
.fsize20 { font-size: 1.2500rem; line-height: 1.4; }
.fsize22 { font-size: 1.3750rem; line-height: 1.5; }
.fsize25 { font-size: 1.5625rem; line-height: 1.2; }
.fsize35 { font-size: 2.1875rem; line-height: 1.2; }



