.blogIcon { color: #17a2b8; cursor: default; }
.blogIcon svg { cursor: default; }
.immagineCont { max-width: 230px; height: 145px; overflow: hidden; float: left; }
.btn-link { color: #17a2b8 !important; }
.tagsCont { float: left; margin: 10px 0 0 0; }
.showFiltri { display: none !important; background-color: #17a2b8 !important; }
.infinite-scroll-component { overflow-x: hidden !important; }

@media (max-width: 900px) {
    .immagineCont  { clear: both; width: 100%; border: 0 !important; padding: 0 !important; max-width: none; }
    .immagineThumb { width: 100%; }
    .descrizione { float: left; }
} 

@media (max-width: 768px) {
    .leftCol { display: none; opacity: 0; -webkit-transition: all 200ms linear;
        -moz-transition: all 200ms linear;
        -ms-transition: all 200ms linear;
        -o-transition: all 200ms linear;
        transition: all 200ms linear; }
    .leftCol.toggled { display: block; opacity: 1; }
    .showFiltri { display: inline-block !important; }
    .hideM { height: 10px !important; }
}