.filtri { background-color: #ececec; margin: 20px 0 20px 0; }
.filtri .form-group { margin-bottom: 0; position: relative; }
.filtri .form-group svg { position: absolute; left: 10px; margin: 0; line-height: 1 !important; color: #f4313f; }
.filtri .form-control { border: 0; border-radius: 0; }
.filtri .form-control:focus { border-color: #b5b5b5 !important; box-shadow: 0 0 0 0.2rem rgba(181, 181, 181, 0.25) }
.filtri .form-group.cercaProdotto { margin-bottom: 10px; }
.filtri .form-group.cercaProdotto input { padding-left: 40px !important; margin-bottom: 0; }
.inviaFiltro { margin: 0 8px; }
.form-control { cursor: pointer; }
.form-control:disabled, .form-control[readonly] {
  background-color: #dedede !important; 
  cursor: default;
}

@media (max-width: 1200px) {
  .inviaFiltro { margin: 0 2px; }
}

@media (max-width: 992px) {
  .form-control { margin-bottom: 10px; }
}