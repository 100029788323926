.slideshowCantina h1 {
	text-transform: uppercase;
}
.containerSlideshowCantine {
	position: relative;
}
.logoCantinaInterno {
	position: absolute;
	left: 10px;
	z-index: 4;
	background-color: rgba(250, 250, 250, 1);
	max-width: 140px;
}
.bottigliaCantina {
	position: absolute;
	left: 50%;
	z-index: 4;
	transform: translateX(-50%) translateY(-50%);
	top: 50%;
}
.bottigliaVino {
	pointer-events: none;
	position: absolute;
	left: 29%;
	z-index: 4;
	transform: translateX(-29%) translateY(-50%);
	top: 50%;
}
.bottigliaGift {
	max-width: 100%;
}
.scrittaSlideVinoCont {
	display: flex;
	justify-content: center;
}
.scrittaSlideVino {
	display: inline-block;
	color: #a4a4a4;
	-moz-transform: rotate(-90deg);
	-webkit-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
	height: 31px;
	top: 0;
	margin-left: 80px;
	padding: 0 10px;
	font-style: italic;
	background: linear-gradient(
		to top,
		transparent 40%,
		#a4a4a4 40%,
		#a4a4a4 45%,
		transparent 45%
	);
	box-sizing: border-box;
}
.scrittaSlideVino span {
	background-color: #ececec;
	padding: 0 10px;
	white-space: nowrap;
}
.downloadPdf {
	position: absolute;
	right: 0;
	top: -30px;
	cursor: pointer;
	z-index: 999 !important;
}
.downloadPdf span {
	color: red;
	font-style: italic;
	margin-right: 10px;
}
.keyTabella {
	color: #444445;
	border-top: 2px solid #ececec;
	text-align: right;
	font-style: italic;
	padding: 2px 10px;
}
.valueTabella {
	color: #1d1d1b;
	border-top: 2px solid #dfdfdf;
	padding: 2px 10px;
}
.formatoBottiglia img {
	margin-bottom: 5px;
}
.titoloPrezzo {
	margin: 20px 0 5px 0;
}
.rowPrezzi {
	padding: 3px 0;
	color: #000;
}
.rowPrezzi.selected {
	background-color: #fcff84 !important;
}
.rowPrezzi:nth-child(even) {
	background-color: #d1d1d1;
}
.rowPrezzi:nth-child(odd) {
	background-color: #f6f5f5;
}

.rowPrezzi.listino:nth-child(2n + 1) {
	background-color: #f6f5f5;
}
/* .rowPrezzi.listino:nth-child(odd) { background-color: #d1d1d1; } */

.giftThumb {
	max-height: 130px;
}

.vinoZoom {
	max-width: 100%;
}
.photogallery_container div {
	z-index: 10;
	cursor: pointer;
}
.photogallery_container div img {
	padding: 5px;
}

/* POPUP REACT */
.popupbox {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	pointer-events: none;
	z-index: 99999;
}

.popupbox[hidden] {
	display: none;
}

.popupbox-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1002;
	background: none repeat scroll 0% 0% #000;
}

.popupbox-content {
	overflow: hidden;
}

.popupbox-wrapper {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
	-webkit-flex-direction: column;
	-moz-flex-direction: column;
	-ms-flex-direction: column;
	-o-flex-direction: column;
	flex-direction: column;
	z-index: 1003;
	position: relative;
}

.popupbox-titleBar {
	overflow: hidden;
	display: block;
	position: relative;
}

.popupbox-btn--close {
	z-index: 1004;
}

.popupbox[data-title='bottom'] .popupbox-content {
	order: 1;
}
.popupbox[data-title='bottom'] .popupbox-titleBar {
	order: 2;
}

/* ----- default theme ----- */

.popupbox-wrapper {
	border-radius: 3px;
	overflow: hidden;
	max-width: 80%;
	min-width: 300px;
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
	background-color: white;
}

.popupbox-content {
	padding: 20px 24px 30px;
}

.popupbox-titleBar {
	font-weight: bold;
	font-size: 18px;
	text-shadow: 0 -1px 1px rgba(0, 0, 0, 0.2);
	padding: 11px 37px 11px 24px;
	border-bottom: 1px #ccc solid;
}

.popupbox-btn--close {
	transition: all 0.5s;
	position: absolute;
	right: 11px;
	top: 11px;
	color: #c1c1c1;
	background: none;
	border: none;
	outline: none;
}

.popupbox-btn--close:hover {
	color: #000;
}

.popupbox.is-active {
	opacity: 1;
	pointer-events: auto;
}

.popupbox[data-title='bottom'] .popupbox-content {
	box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}
.popupbox[data-title='bottom'] .popupbox-titleBar {
	box-shadow: none;
	border-top: 1px #ccc solid;
}

@media (max-width: 992px) {
	.scrittaSlideVinoCont {
		margin: 5px 0 20px 5px;
		background: linear-gradient(
			to bottom,
			transparent 40%,
			#a4a4a4 40%,
			#a4a4a4 45%,
			transparent 45%
		);
	}
	.downloadPdf {
		top: 0px;
	}
	.slideshowVino .pl-5 {
		padding: 1rem 2rem !important;
	}
	.bottigliaVino {
		transform: none;
		left: auto;
		bottom: 0;
		top: 7%;
		right: 10%;
		max-height: 565px;
	}
	.scrittaSlideVino {
		transform: none;
		padding: 0;
		margin: 0;
		background: none;
	}
	.slideshowVino .slick-dots {
		bottom: auto;
		top: 10px;
		right: 5%;
		left: auto;
		transform: none;
	}
	.slideshowVino .slick-dots li {
		margin: 0 9px;
	}
	.slideshowVino h3 {
		font-size: 1.375rem;
		line-height: 1.5;
	}
	.slideshowVino p {
		font-size: 1rem;
		line-height: 1.8;
	}
}

@media (max-width: 768px) {
	.bottigliaVino {
		top: 100px;
	}
}

@media (max-width: 576px) {
	.bottigliaVino {
		top: 100px;
		right: 5%;
		max-height: 450px;
	}
	.bottigliaGift {
		max-height: 300px;
	}
	.slideshowVino .pl-5 {
		padding: 2rem !important;
	}
	.slideshowVino .slick-dots {
		top: 50px;
	}
	.downloadPdf {
		right: 20px;
		top: -10px;
	}
	.scrittaSlideVinoCont {
		margin: 30px 0 20px 0;
	}
	.keyTabella {
		padding: 2px 8px 2px 0 !important;
	}
	.valueTabella {
		padding: 2px 0 0 8px !important;
	}
	.keyTabella,
	.valueTabella {
		font-size: 0.875rem !important;
		line-height: 1.5 !important;
	}
}

@media (max-width: 500px) {
	.pSlideshow {
		min-height: 65px;
	}
}

@media (max-width: 480px) {
	.bottigliaVino {
		top: 100px;
		max-height: 380px;
	}
	.formatoBottiglia span {
		font-size: 0.875rem;
		line-height: 1.5;
	}
	.titoloPrezzo {
		font-size: 0.6875rem;
		line-height: 1.5;
	}
}

@media (max-width: 400px) {
	.bottigliaVino {
		top: 100px;
		max-height: 320px;
	}
	.slideshowVino .pl-5 {
		padding: 2rem !important;
	}
	.slideshowVino h3 {
		font-size: 1rem;
		line-height: 1.5;
	}
	.slideshowVino p {
		font-size: 0.875rem;
		line-height: 1.5;
	}
	.formatoBottiglia span {
		font-size: 0.6875rem;
		line-height: 1.5;
	}
	.titoloPrezzo {
		font-size: 0.625rem;
		line-height: 1.5;
	}
	.rowPrezzi {
		font-size: 0.6875rem;
		line-height: 1.5;
	}
}

@media (max-width: 350px) {
	.bottigliaVino {
		top: 80px;
		max-height: 300px;
	}
	.slideshowVino .pl-5 {
		padding: 2rem !important;
	}
	.slideshowVino h3 {
		font-size: 1rem;
		line-height: 1.5;
	}
	.slideshowVino p {
		font-size: 0.875rem;
		line-height: 1.5;
	}
	.formatoBottiglia span {
		font-size: 0.625rem;
		line-height: 1.5;
	}
}
