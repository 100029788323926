.cantineContainer {
	margin: 100px 0 0 0;
}

.spinnerCentered {
	left: 50%;
	transform: translateX(-50%);
}

.gruppoCantine {
}
.gruppoCantine > div {
	opacity: 0;
	-webkit-transition: all 700ms ease-in-out;
	-moz-transition: all 700ms ease-in-out;
	-ms-transition: all 700ms ease-in-out;
	-o-transition: all 700ms ease-in-out;
	transition: all 700ms ease-in-out;
}
.appear {
	opacity: 1;
}
.boxCantina {
	position: relative;
	overflow: hidden;
	cursor: pointer;
}
.immaginePrincipale,
.immagineAlternativa {
	width: 100%;
}
.immaginePrincipale.colore,
.immagineAlternativa.colore {
	opacity: 0;
	position: absolute;
}
.boxCantina .immagineAlternativa {
	display: none;
}
.boxCantina span {
	width: 100%;
}
.boxCantina h1 {
	color: white;
	position: absolute;
	text-transform: uppercase;
	opacity: 0;
	z-index: 4;
}
.logoCantina {
	position: absolute;
	z-index: 4;
	pointer-events: none;
	background-color: rgba(250, 250, 250, 1);
	max-width: 120px !important;
}
.titoloCantina {
	pointer-events: none;
}
.gridCantina {
	overflow: hidden;
}
.gridCantina.uno .logoCantina {
	right: 0;
}
.gridCantina.due .logoCantina {
	right: 0;
	bottom: 0;
}
.gridCantina.tre .logoCantina {
	right: 0;
	top: 0;
}
.gridCantina.quattro .logoCantina {
	right: 0;
	bottom: 0;
}
.gridCantina.cinque .logoCantina {
	left: 0;
	bottom: 0;
}
.gridCantina.sei .logoCantina {
	right: 0;
	bottom: 0;
}
.gridCantina.uno .boxCantina h1 {
	bottom: 30px;
	left: 20px;
}
.gridCantina.due .boxCantina h1 {
	bottom: 240px;
	text-align: center;
}
.gridCantina.tre .boxCantina h1 {
	top: 250px;
	text-align: center;
}
.gridCantina.quattro .boxCantina h1 {
	bottom: 30px;
	left: 20px;
}
.gridCantina.cinque .boxCantina h1 {
	bottom: 30px;
	right: 20px;
}
.gridCantina.sei .boxCantina h1 {
	bottom: 30px;
	left: 20px;
}
.gridCantina.due {
	margin-top: 40px;
}
.gridCantina.quattro {
	margin-top: 40px;
}
.gridCantina.cinque {
	margin-top: -40px;
}
.gridCantina img {
	max-width: 100%;
	height: auto;
}

@media (max-width: 991px) {
	.cantineContainer {
		margin-top: 20px;
	}
	#gridCantine {
		grid-template-columns: repeat(18, 1fr);
		grid-auto-rows: 10px;
	}
	.gridCantina {
		text-align: center;
	}
	.gridCantina img {
		max-height: 100%;
		max-width: 100%;
		height: auto;
	}
	.gridCantina.uno {
		grid-column-end: span 8;
		grid-row-end: span 14;
	}
	.gridCantina.due {
		grid-column-end: span 5;
		grid-row-end: span 33;
		margin: 0;
	}
	.gridCantina.tre {
		grid-column-end: span 5;
		grid-row-end: span 33;
	}
	.gridCantina.quattro {
		grid-column-end: span 8;
		grid-row-end: span 14;
		margin: 0;
	}
	.gridCantina.cinque {
		grid-column-end: span 8;
		grid-row-end: span 14;
		margin: 0;
	}
	.gridCantina.sei {
		grid-column-end: span 8;
		grid-row-end: span 14;
		margin: 0 0 0 3px;
	}
}

@media (max-width: 767px) {
	.cantineContainer {
		margin-top: 20px;
	}
	#gridCantine {
		grid-template-columns: repeat(18, 1fr);
		grid-auto-rows: 9px;
	}
	.gridCantina {
		text-align: center;
	}
	.gridCantina img {
		max-height: 100%;
	}
	.gridCantina.uno {
		grid-column-end: span 9;
		grid-row-end: span 12;
	}
	.gridCantina.due {
		grid-column-end: span 9;
		grid-row-end: span 12;
		margin: 0;
	}
	.gridCantina.tre {
		grid-column-end: span 9;
		grid-row-end: span 12;
	}
	.gridCantina.quattro {
		grid-column-end: span 9;
		grid-row-end: span 12;
		margin: 0;
	}
	.gridCantina.cinque {
		grid-column-end: span 9;
		grid-row-end: span 12;
		margin: 0;
	}
	.gridCantina.sei {
		grid-column-end: span 9;
		grid-row-end: span 12;
		margin: 0;
	}
	.gridCantina .logoCantina {
		max-height: 120px;
	}
	.gridCantina.uno .logoCantina {
		right: 15px;
		margin-top: -190px;
	}
	.gridCantina.due .logoCantina {
		right: 15px;
		top: 0;
		margin-bottom: 0;
		margin-top: -190px;
	}
	.gridCantina.tre .logoCantina {
		left: 15px;
		top: 0;
		margin-top: -190px;
	}
	.gridCantina.quattro .logoCantina {
		left: 15px;
		top: 0;
		margin-bottom: 0;
		margin-top: -190px;
	}
	.gridCantina.cinque .logoCantina {
		right: 15px;
		top: 0;
		margin-bottom: 0;
		margin-top: -190px;
	}
	.gridCantina.sei .logoCantina {
		right: 15px;
		top: 0;
		margin-bottom: 0;
		margin-top: -190px;
	}
	.gridCantina.due .boxCantina h1 {
		bottom: 30px;
		left: 12px;
		transform: none;
	}
	.gridCantina.tre .boxCantina h1 {
		bottom: 30px;
		left: 12px;
		top: auto;
		transform: none;
	}
	.gridCantina.due .boxCantina .immagineAlternativa,
	.gridCantina.tre .boxCantina .immagineAlternativa {
		display: block;
	}
	.gridCantina.due .boxCantina .immaginePrincipale,
	.gridCantina.tre .boxCantina .immaginePrincipale {
		display: none;
	}
}

@media (max-width: 576px) {
	#gridCantine {
		grid-auto-rows: minmax(20px, auto);
	}
	.gridCantina {
		grid-row-end: auto !important;
	}
	.gridCantina .logoCantina {
		max-height: 120px;
	}
}

@media (max-width: 500px) {
	#gridCantine {
		width: 100%;
	}
	.gridCantina {
		grid-column-end: span 18 !important;
		grid-row-end: auto !important;
	}
	.gridCantina .immaginePrincipale,
	.gridCantina .immagineAlternativa {
		width: 100%;
	}
	.gridCantina.cinque .boxCantina h1 {
		left: 20px;
		right: auto;
	}
	.gridCantina .logoCantina {
		max-height: none;
		right: 15px !important;
		left: auto !important; /*  bottom: auto !important; top: -120px;   */
	}
}
