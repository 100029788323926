.tags { padding-left: 0 !important; }
.tags .badge { margin: 2px; cursor: pointer; }
.categorieBlog { padding-left: 0 !important; }
.categorieBlog h4 { margin-bottom: 15px; }
.categorieBlog button { border: 0; border-radius: 0; background-color: #e3e3e3; color: black; }
.categorieBlog button:hover, .categorieBlog button:active, .categorieBlog .selected { color: white !important; background-color: inherit; }
.categorieBlog div { margin: 7px 0; }
.tags h4, .categorieBlog h4 { color: #245d38; }

@media (max-width: 400px) {
    .spacer30 { height: 15px !important; }
}
