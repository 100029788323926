@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.bg-grigio { background-color: #ececec; }
.slideshow {  overflow: hidden; }
.slick-slider { width: 100%; z-index: 2 }
.slick-slider img { line-height: 0; display: inline; z-index: -1; }
.slick-dots { width: auto; bottom: 5%; transform: translateY(-60%); left: 50%; transform: translateX(-50%); }
.slick-dots li { width: 26px; height: 6px; }
.slick-dots li button { background: #b5b5b5; width: 26px; height: 6px; padding: 0; }
.slick-dots li button::before { display: none; }
.slick-dots li.slick-active button { background: #1d5c39; }
.spinnerSlideshow { right: 50%; transform: translateX(50%); }
.mappaItalia { position: absolute; left: 50%; transform: translateX(-50%); bottom: -150px; opacity: .1; z-index: 3; }

@media (min-width: 768px) {
  .slick-dots { width: 26px; bottom: auto; top: 50%; transform: translateY(-50%); left: 24px; }
  .spinnerSlideshow { right: 25%; transform: translateX(50%); }
  .slick-dots { bottom: 60%; }
}

@media (min-width: 300px) {
  .mappaItalia { bottom: 0; z-index: 1; }
}