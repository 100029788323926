.menuToggle
{
    display: block;
    position: absolute;
    z-index: 10001;
    right: 0;
    -webkit-user-select: none;
    user-select: none;
}

.menuToggle input
{
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    -webkit-touch-callout: none;
}

.menuToggle span
{
    display: block;
    width: 39px;
    height: 3px;
    margin: 0 auto 7px;
    position: relative;
    background: #000000;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0), background 0.5s cubic-bezier(0.77,0.2,0.05,1.0), opacity 0.55s ease;
}

.menuToggle span:first-child
{
    transform-origin: 0% 0%;
}

.menuToggle span:nth-last-child(1)
{
    transform-origin: 0% 100%;
}

.menuToggle input:checked ~ span
{
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
}

.menuToggle input:checked ~ span:nth-last-child(2)
{
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

.menuToggle input:checked ~ span:nth-last-child(1)
{
    transform: rotate(-45deg) translate(0, 2px);
}

.menuToggle.reset input:checked ~ span {
  opacity: 1;
  transform: none;
}