.root { color: #444445; font-family: 'MontserratRegular', Georgia, serif; }
body { overflow-x: hidden; }
html { font-size: 12px; overflow: visible !important; position: relative !important; top: auto !important; width: 100% !important; }
p { margin-bottom: 0 !important; }

@media (min-width: 300px) {
  html { font-size: 13px; }
}

@media (min-width: 350px) {
  html { font-size: 14px; }
}

@media (min-width: 576px) {
  html { font-size: 14px; }
}

@media (min-width: 768px) {
  html { font-size: 14px; }
}

@media (min-width: 992px) {
  html { font-size: 15px; }
}

@media (min-width: 1200px) {
  html { font-size: 16px; }
}

/* UTILS */
:global(.overflowh) { overflow: hidden; }

/* FONTS */
:global(.fsemib) { font-family: 'MontserratSemiBold', Georgia, serif; }

/* SPACER */
:global(.spacer15) { height: 15px; }
:global(.spacer30) { height: 30px; }

/* FONT-SIZE */
:global(.fsize12) { font-size: 0.750rem; line-height: 1.5; }
:global(.fsize14) { font-size: 0.875rem; line-height: 1.5; }
:global(.fsize15) { font-size: 0.937rem; line-height: 1.5; }
:global(.fsize18) { font-size: 0.875rem; line-height: 1.5; }
:global(.fsize19) { font-size: 1rem; line-height: 1.5; }
:global(.fsize20) { font-size: 1.2500rem; line-height: 1.4; }
:global(.fsize22) { font-size: 1.3750rem; line-height: 1.5; }
:global(.fsize25) { font-size: 1.5625rem; line-height: 1.2; }
:global(.fsize35) { font-size: 2.1875rem; line-height: 1.2; }


