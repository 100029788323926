.boxGift > div { opacity: 0; -webkit-transition: all 700ms ease-in-out; -moz-transition: all 700ms ease-in-out; -ms-transition: all 700ms ease-in-out; -o-transition: all 700ms ease-in-out; transition: all 700ms ease-in-out; margin-bottom: 50px; cursor: pointer; }
.boxGift h3 { display: block; text-align: center; }
.boxGift h4 { display: block; text-align: center; margin-bottom: 5px; }
.boxGift .immagineGiftElenco { max-width: 100%; height: 182px; }
.boxGift .selected img { padding: 10px 20px; border-left: 2px solid #d1d1d1; border-right: 2px solid #d1d1d1; border-top: 2px solid #d1d1d1; }
.lineaRossa { width: 100%; height: 45px; margin: 15px 0; background: linear-gradient(to right, transparent 50%, #d1d1d1 50%, #d1d1d1 51%, transparent 51% ); }

.boxGift .Bellavista.selected img { border-left: 2px solid #f4313f; border-right: 2px solid #f4313f; border-top: 2px solid #f4313f; }
.boxGift .Acquagiusta.selected img { border-left: 2px solid #cdb4a7; border-right: 2px solid #cdb4a7; border-top: 2px solid #cdb4a7; }
.boxGift .ContadiCastaldi.selected img { border-left: 2px solid #dddc00; border-right: 2px solid #dddc00; border-top: 2px solid #dddc00; }
.boxGift .SellaMosca.selected img { border-left: 2px solid #58b1c1; border-right: 2px solid #58b1c1; border-top: 2px solid #58b1c1; }
.boxGift .Petra.selected img { border-left: 2px solid #7a5b3a; border-right: 2px solid #7a5b3a; border-top: 2px solid #7a5b3a; }
.boxGift .Teruzzi.selected img { border-left: 2px solid #84754f; border-right: 2px solid #84754f; border-top: 2px solid #84754f; }

@media (max-width: 1200px) {
    .boxGift .selected::before { left: 35px; }
    .boxGift .selected::after { right: 35px; }
}

@media (max-width: 769px) {
    .boxGift .selected::before { left: 45px; }
    .boxGift .selected::after { right: 45px; }
}

@media (max-width: 576px) {

}