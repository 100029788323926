.thead .titoloPrezzo { margin-top: 10px; }
.filtrandoPer { margin: 0 0 10px 0; background-color: #ececec; }
.filtrandoPer b { margin-left: 5px; }
#elencoPrezzi .rowPrezzi .nomeVino { padding: 0 15px }
.disponibilita { color: green; }
.showMobile.dispo { display: none; }

@media (max-width: 768px) {
    .rowPrezzi div { padding: 5px 5px !important; font-size: 0.875rem; line-height: 1.5; }
    .vaiProdotto { display: block; clear: both; }
    .showMobile.dispo { background: #9dc88c; color: black; padding: 0 5px 0 0; text-align: right; display: block; }
    #elencoPrezzi .disponibilita { display: none !important; }
    #elencoPrezzi .nomeVino { flex: 0 0 33.333333%; max-width: 33.333333%; }
    /* #elencoPrezzi .nomeVino { flex: 0 0 33.333333%; max-width: 33.333333%; }
    #elencoPrezzi .formatoDesc { flex: 0 0 25%; max-width: 25%; }
    #elencoPrezzi .annata { flex: 0 0 8.333333%; max-width: 8.333333%; } */
}

@media (max-width: 480px) {
    /* #elencoPrezzi .nomeVino { flex: 0 0 25%; max-width: 25%; } */
/*     #elencoPrezzi .prezzi { flex: 0 0 16.666667%; max-width: 16.666667%; } */
    .rowPrezzi div { padding: 3px 3px !important; font-size: 0.7rem; line-height: 1.5; }
    .showMobile.dispo {  font-size: 0.875rem; }
}