.boxVino > div { opacity: 0; -webkit-transition: all 700ms ease-in-out; -moz-transition: all 700ms ease-in-out; -ms-transition: all 700ms ease-in-out; -o-transition: all 700ms ease-in-out; transition: all 700ms ease-in-out; margin-bottom: 50px; cursor: pointer; }
.boxVino h3 { display: block; text-align: center; }
.boxVino h4 { display: block; text-align: center; margin-bottom: 5px; }
.boxVino .immagineVinoElenco { max-height: 350px; }
.boxVino .selected img { padding: 5px; border-top: 2px solid #d1d1d1; }
.boxVino .selected::before { content: ''; position: absolute; border-left: 2px solid #d1d1d1; z-index: 999; width: 5px; height: 30px; left: 56px; top: 0; }
.boxVino .selected::after { content: ''; position: absolute; border-right: 2px solid #d1d1d1; z-index: 999; width: 5px; height: 30px; right: 56px; top: 0; }
.lineaRossa { width: 100%; height: 45px; margin: 15px 0; background: linear-gradient(to right, transparent 50%, #d1d1d1 50%, #d1d1d1 51%, transparent 51% ); }

.boxVino .Bellavista.selected::before { border-left: 2px solid #f4313f; }
.boxVino .Bellavista.selected::after { border-right: 2px solid #f4313f; }
.boxVino .Bellavista.selected img { border-top: 2px solid #f4313f; }
.boxVino .Acquagiusta.selected::before { border-left: 2px solid #cdb4a7; }
.boxVino .Acquagiusta.selected::after { border-right: 2px solid #cdb4a7; }
.boxVino .Acquagiusta.selected img { border-top: 2px solid #cdb4a7; }
.boxVino .ContadiCastaldi.selected::before { border-left: 2px solid #dddc00; }
.boxVino .ContadiCastaldi.selected::after { border-right: 2px solid #dddc00; }
.boxVino .ContadiCastaldi.selected img { border-top: 2px solid #dddc00; }
.boxVino .SellaMosca.selected::before { border-left: 2px solid #58b1c1; }
.boxVino .SellaMosca.selected::after { border-right: 2px solid #58b1c1; }
.boxVino .SellaMosca.selected img { border-top: 2px solid #58b1c1; }
.boxVino .Petra.selected::before { border-left: 2px solid #7a5b3a; }
.boxVino .Petra.selected::after { border-right: 2px solid #7a5b3a; }
.boxVino .Petra.selected img { border-top: 2px solid #7a5b3a; }
.boxVino .Teruzzi.selected::before { border-left: 2px solid #84754f; }
.boxVino .Teruzzi.selected::after { border-right: 2px solid #84754f; }
.boxVino .Teruzzi.selected img { border-top: 2px solid #84754f; }

@media (max-width: 1200px) {
    .boxVino .selected::before { left: 35px; }
    .boxVino .selected::after { right: 35px; }
}

@media (max-width: 769px) {
    .boxVino .selected::before { left: 45px; }
    .boxVino .selected::after { right: 45px; }
}

@media (max-width: 576px) {
    .boxVino .selected { border-top: 2px solid #d1d1d1; }
    .boxVino .selected img { border: 0 !important; }
    .boxVino .selected::before { left: 0; }
    .boxVino .selected::after { right: 0; }

    .boxVino .Bellavista.selected { border-top: 2px solid #f4313f; }
    .boxVino .Acquagiusta.selected { border-top: 2px solid #cdb4a7; }
    .boxVino .ContadiCastaldi.selected { border-top: 2px solid #dddc00; }
    .boxVino .SellaMosca.selected { border-top: 2px solid #58b1c1; }
    .boxVino .Petra.selected { border-top: 2px solid #7a5b3a; }
    .boxVino .Teruzzi.selected { border-top: 2px solid #84754f; }
}