.loginAuth .logoContainer svg { width: 120px !important; height: 120px !important; }
.loginAuth { margin-top: 30px; margin-bottom: 30px; }
.loginAuth .logoContainer { text-align: center; }
.titleLogin { display: block; margin-bottom: 20px; }
.boxLogin { margin: 50px 0; }
.eyePasswordShow { position: absolute; z-index: 100; right: 11px; top: 7px; display: block; }
.eyePasswordHide { position: absolute; z-index: 100; right: 10px; top: 7px; display: block; }
.eyePasswordHide { display: none; }
.eyePasswordShow.selected { display: none; }
.eyePasswordHide.selected { display: block; }
.loginAuth .input-group input { border-radius: 0.25rem !important; }