.elencoGift { margin-top: 20px; }

.spinnerSlideshow.leftOriented { left: 25%; transform: translateX(-50%); }
.spinnerSlideshow.centerOriented { left: 50%; transform: translateX(-50%); }
.spinnerSlideshow.hide { display: none; }
.bottigliaCantina { pointer-events: none; }
.appear { opacity: 1; }

@media (max-width: 992px) {
  .slideshowCantina .pl-5 {
    padding: 1rem 2rem !important;
  }
  .slideshowCantina .slick-dots { bottom: auto; top: 5%; right: 5%; left: auto; }
  .slideshowCantina .slick-dots li { margin: 0 9px;}
  .slideshowCantina h3 { font-size: 1.3750rem; line-height: 1.5; }
  .slideshowCantina p { font-size: 1rem; line-height: 1.8; }
  .immagineSlideshowP { width: 100%; position: relative; max-height: 600px; }
  .immagineSlideshowP img { width: 100%; }
  .spinnerSlideshow.leftOriented { left: 50%; top: 20%; right: auto; transform: translateX(0);  }
  .bottigliaCantina { transform: none; left: auto; bottom: 0; top: 20%; right: 10%; }
}

@media (max-width: 768px) {
  .immagineSlideshowP { max-height: none; }
  .immagineSlideshowP img { transform: none; top: 0; }
  .bottigliaCantina { top: 15%; }
}

@media (max-width: 576px) {
  .bottigliaCantina { top: 12%; max-height: 450px; }
  .slideshowCantina .pl-5 { padding: 2rem !important; }
  .slideshowCantina .slick-dots { right: 0; }
}

@media (max-width: 480px) {
  .bottigliaCantina { top: 9%; max-height: 380px; }
}

@media (max-width: 400px) {
  .bottigliaCantina { top: 9%; max-height: 320px; }
  .slideshowCantina .pl-5 { padding: 2rem !important; }
  .slideshowCantina h3 { font-size: 1rem; line-height: 1.5; }
  .slideshowCantina p { font-size: 0.875rem; line-height: 1.5; }
}

@media (max-width: 350px) {
  .bottigliaCantina { top: 8%; max-height: 300px; }
  .slideshowCantina .pl-5 { padding: 2rem !important; }
  .slideshowCantina h3 { font-size: 1rem; line-height: 1.5; }
  .slideshowCantina p { font-size: 0.875rem; line-height: 1.5; }
}